import React from "react"
import Layout from "../components/Layout";
import '../styles/global.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '@material-ui/core/Button';

export default function Home() {
  return (
    <Layout>
      <h1 className='welcomeHeading'>Welcome to the Michigan eArly disease Progression cohort in COPD (MAP COPD)</h1>
      <p className='welcomeMatter'>MAP COPD is a research study being conducted at the University of Michigan.  We are reaching 
        out to people who are currently smoking or have previously smoked and may be at risk of developing 
        chronic obstructive pulmonary disease (COPD) or who have early COPD.</p> 
        
      <p className='welcomeMatter'>COPD is a slowly developing lung disease that is common among individuals exposed to tobacco use. 
        As the disease progresses, airways narrow and lung tissue becomes damaged, negatively affecting how 
        air flows throughout the lungs. COPD symptoms include shortness of breath, cough and increased mucus 
        production. Learn more about <a href='https://www.copdfoundation.org/What-is-COPD/Understanding-COPD/What-is-COPD.aspx'  target="_blank" rel="noopener noreferrer" className='linkInParagraph'>COPD</a>.</p>
        
      <p className='welcomeMatter'>The early stages of COPD are often overlooked since affected individuals may not develop specific 
        respiratory symptoms until years after the disease begins. Not all people with a history of smoking go 
        on to develop COPD. The MAP COPD study aims to understand the earliest signs of the disease and why some 
        people develop early COPD while others do not.</p>

      <p className='welcomeMatter'>We are looking for volunteers who currently smoke or have previously smoked to participate in our study.  
        If you believe you have mild or moderate COPD, you may also be eligible.  We are looking to enroll individuals 
        between 30 and 55 years of age.  If interested, we would begin by asking you some questions over the phone. If 
        we believe you would be eligible, we would then ask you to come to the University of Michigan in Ann Arbor to be further evaluated.</p>   

      <p className='welcomeMatter'>If you are interested in helping us find out more about early <b>MAP COPD</b> <Button variant="contained" size='large' onClick={()=>window.open('https://umhealthresearch.org/#studies/HUM00176147', "_blank")} style={{backgroundColor:'#B23114', color:'whitesmoke'}}>
        <b>Learn More</b> 
      </Button></p>  
    </Layout>
  )
}
